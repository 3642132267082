<template>
  <vx-card :title="this.pageTitle">
    <template slot="actions">
      <div>
        <vs-button
          v-if="$can('create', 'salvage')"
          size="small" color="success" type="border" @click="createScheduledReport()"
        >
          Crear Reporte Programado
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="scheduledReports" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="periodicity">Frecuencia</vs-th>
            <vs-th sort-key="recipients">Destinatarios</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td>
                <p class="font-medium">{{ record.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.periodicity | periodicity_label(record.execution_hour, record.dates) }}</p>
              </vs-td>
              <vs-td>
                <p v-for="recipient in record.recipients" class="font-medium">{{ recipient }}</p>
              </vs-td>
              <vs-td>
                <div class="flex">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!($can('retrieve', 'salvage') || $can('update', 'salvage'))"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click="editScheduledReport(record)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      :disabled="!$can('delete', 'salvage')"
                      radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                      @click="confirmDelete(record.id)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import EntriesClient from "../../utils/entriesClient";
  import ReportsClient from "../../utils/reportsClient";
  import Notifier from "./../../utils/notification";
  import vSelect from "vue-select";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("salvages");
  const REPORTS_CLIENT = new ReportsClient("salvages");

  export default {
    data() {
      return {
        pageTitle: "Reportes Programados",
        scheduledReports: [],
        newScheduledReport: {
          id: null,
          name: "",
          periodicity: "",
          execution_hour: null,
          dates: [],
          recipients: [],
        },
        popupActive: false,
      };
    },

    mounted() {
      this.$vs.loading();
      this.setPageTitle()
        .then(() => {
          this.getScheduledReports()
            .then(() => {
              this.$vs.loading.close();
            });
        });
    },

    methods: {
      async setPageTitle() {
        await ENTRIES_CLIENT.retrieve({
          queryStrings: {representation_type: "simple"},
          pathParameters: {entryId: this.$route.params.entryId}
        })
          .then(response => {
            this.pageTitle = `Reportes Programados: ${response.data.name}`
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async getScheduledReports(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await REPORTS_CLIENT.all({pathParameters: {entryId: this.$route.params.entryId}})
          .then(response => {
            this.scheduledReports = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async deleteScheduledReport(scheduledReportId) {
        if (scheduledReportId !== null && scheduledReportId !== undefined && scheduledReportId !== "") {
          this.$vs.loading();
          await REPORTS_CLIENT.delete({
            pathParameters: {entryId: this.$route.params.entryId, scheduledReportId: scheduledReportId}
          })
            .then(response => {
              notifier.notification("deleted");
              this.getScheduledReports();
            })
            .catch(error => {
              notifier.alertMessage("error");
            });
          this.$vs.loading.close();
        }
      },

      confirmDelete(scheduledReportId) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteScheduledReport(scheduledReportId);
          }
        });
      },

      createScheduledReport() {
        this.$router.push({
          name: "create-scheduled-report-salvage",
          params: {
            entryId: this.$route.params.entryId
          }
        });
      },

      editScheduledReport(scheduledReport) {
        this.$router.push({
          name: "edit-scheduled-report-salvage",
          params: {
            entryId: this.$route.params.entryId,
            reportId: scheduledReport.id,
          }
        });
      },
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
